import React, { useEffect, useState } from "react";
import { Link, useLocation } from "react-router-dom";
import { Breadcrumb, Button, Form, InputGroup } from 'react-bootstrap';
import { Identities, WebApps as WebAppsApi } from "../onsight-plus";
import EditModal from "../components/EditModal";
import OTable, { standardActions } from "../components/OTable";
import RoleAssignment from "../models/RoleAssignment";
import ConfirmationModal from "../components/ConfirmationModal";

export const UserConfig = () => {
    const location = useLocation();
    const user = location.state;
    const [selectedItem, setSelectedItem] = useState(new RoleAssignment());
    const [selectedItemIndex, setSelectedItemIndex] = useState(-1);
    const [showDialog, setShowDialog] = useState(false);
    const [isNewItem, setIsNewItem] = useState(true);
    const [showDeleteConfirmation, setShowDeleteConfirmation] = useState(false);
    const [roleAssignments, setRoleAssignments] = useState([]);
    const [apps, setApps] = useState([]);
    const [roleOptions, setRoleOptions] = useState([]);
    const [selectedAppId, setSelectedAppId] = useState("");
    const [selectedRole, setSelectedRole] = useState("");

    const showAddDialog = () => {
        setSelectedItem(new RoleAssignment());
        setIsNewItem(true);
        setShowDialog(true);
    };

    const showDeleteDialog = (item, index) => {
        setSelectedItem(item);
        setSelectedItemIndex(index);
        setShowDeleteConfirmation(true);
    };

    /**
     * Load the available web apps in the current tenant.
     */
    const loadAppOptions = async () =>
    {
        try {
            const allApps = await WebAppsApi.getAll();
            setApps(allApps);
        } catch (error) {
            console.log(error);
        }
    };

    /**
     * Load the user's list of role assignments, both manually-assigned and inherited from group memberships.
     */
    const loadRoleAssignments = async () => {
        try {
            const roles = await Identities.getUserRoleAssignments(user.userId);
            setRoleAssignments(roles);
        } catch (error) {
            console.log(error);
        }
    };

    const handleSelectedAppChange = (event) => {
        const appId = event.target.value;
        setSelectedAppId(appId);
        const selectedApp = apps.find(app => app.id === appId);
        const availableRoles = selectedApp ? selectedApp.customRoles : [];
        setRoleOptions(availableRoles);
        setSelectedRole(availableRoles.length > 0 ? availableRoles[0].name : "");
    };

    const addItem = async () => {
        try {
            const newAssignments = await Identities.assignRole(user.userId, selectedAppId, selectedRole);
            setRoleAssignments(newAssignments);
            setShowDialog(false);
        } catch (error) {
            console.log(error);
        }
    };

    
    const deleteSelectedItem = async () => {
        try {
            await Identities.revokeRole(user.userId, selectedItem.appId, selectedItem.roleName);
            await loadRoleAssignments();
            setShowDeleteConfirmation(false);
        }
        catch (error) {
            console.log(error);
        }
    };

    useEffect(() => {
        loadRoleAssignments();
        loadAppOptions();
    }, []);

    useEffect(() => {
        if (apps.length > 0) {
            handleSelectedAppChange({target: { value: apps[0].id}});
        }
    }, [apps]);

    return (
        <>
            <h3>{user.name}</h3>
            <Breadcrumb>
                <Breadcrumb.Item linkAs={Link} linkProps={{ to: "/users" }}>Users</Breadcrumb.Item>
                <Breadcrumb.Item active>{user.name}</Breadcrumb.Item>
            </Breadcrumb>
            <p />
            <span>App Roles</span>
            <div>
                <div className="content-toolbar">
                    <Button onClick={showAddDialog}>Assign Role</Button>
                </div>
                <OTable items={roleAssignments} columns={[
                        { header: "App Name", content: ra => ra.appName },
                        { header: "Role", content: ra => ra.roleName },
                        { header: "Source", content: ra => ra.source },
                        standardActions({
                            delete: showDeleteDialog,
                            canDelete: item => item.canRevoke
                        })
                    ]} />
            </div>

            <EditModal
                itemName="Role Assignment"
                item={selectedItem}
                itemId={selectedItemIndex}
                show={showDialog}
                isNew={isNewItem}
                onAddClick={addItem}
                onCancelClick={() => setShowDialog(false)}>
                <InputGroup className="mb-3">
                    <InputGroup.Text id="basic-addon1">App Name</InputGroup.Text>
                    <Form.Select name="name"
                        onChange={handleSelectedAppChange}
                        aria-label="Role Name"
                        aria-describedby="basic-addon1">
                        {apps.map(app => <option key={app.id} value={app.id}>{app.displayName}</option>)}
                    </Form.Select>
                </InputGroup>
                <InputGroup className="mb-3">
                    <InputGroup.Text id="basic-addon2">Role</InputGroup.Text>
                    <Form.Select name="description"
                        onChange={evt => setSelectedRole(evt.target.value)}
                        aria-label="Description"
                        aria-describedby="basic-addon2">
                        {roleOptions.map(opt => <option key={opt.name} value={opt.name}>{opt.name}</option>)}
                    </Form.Select>
                </InputGroup>
            </EditModal>

            <ConfirmationModal 
                show={showDeleteConfirmation}
                title="Delete Role Assignment"
                item={selectedItem}
                itemId={selectedItemIndex}
                onYesClick={deleteSelectedItem}
                onNoClick={() => setShowDeleteConfirmation(false)}>
                    Are you sure you want to delete '{selectedItem.appName}/{selectedItem.roleName}'?
            </ConfirmationModal>
        </>
    );
};