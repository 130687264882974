class WebAppConfigModel {
    configId = "";
    type;
    displayName = "";
    userMapping = { 
        role: "*" 
    };
    definition = {};

    constructor(configType) {
        this.type = configType;
    }
}

export default WebAppConfigModel;