import React from "react";
import { DragDropContext, Draggable, Droppable } from "react-beautiful-dnd";
import { Dropdown, DropdownButton, Table } from "react-bootstrap";

const OTable = ({items, columns, canReorderRows, getItemId, onRowMoved}) => {
    const canDnD = onRowMoved && getItemId;
    if (!getItemId) {
        getItemId = (item, index) => index.toString();
    }

    const handleDragEnd = (result) => {
        if (onRowMoved && result.destination && result.destination.index != result.source.index) {
            onRowMoved(result);
        }
    };

    const dropId = "foo";

    let tableItems = items && items.map((item, rowIndex) =>
        <Draggable draggableId={getItemId(item, rowIndex)} index={rowIndex} key={getItemId(item, rowIndex)} isDragDisabled={!canDnD}>
            {provided => (
                <tr ref={provided.innerRef} {...provided.draggableProps} {...provided.dragHandleProps}>
                    {columns.map((col, colIndex) => <td key={colIndex} className={col.alignment || "left"} style={{ width: col.width || "inherit" }}>{col.content(item, rowIndex)}</td>)}
                    {provided.placeholder}
                </tr>
            )}
        </Draggable>);

    let tableBody = (
        <Droppable droppableId={dropId} isDropDisabled={!canDnD}>
            {provided => (
                <tbody ref={provided.innerRef} {...provided.droppableProps}>
                    {tableItems}
                    {provided.placeholder}
                </tbody>
            )}
        </Droppable>);

    return (
        <DragDropContext onDragEnd={handleDragEnd}>
            <Table>
                <thead>
                    <tr key="ix">
                        {columns.map((col, idx) => <th key={idx} className={col.alignment || "left"}>{col.header}</th>)}
                    </tr>
                </thead>
                {tableBody}
            </Table>
        </DragDropContext>
    );
};

export const VerticalEllipsis = 
    <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" className="bi bi-three-dots-vertical" viewBox="0 0 16 16">
        <path d="M9.5 13a1.5 1.5 0 1 1-3 0 1.5 1.5 0 0 1 3 0zm0-5a1.5 1.5 0 1 1-3 0 1.5 1.5 0 0 1 3 0zm0-5a1.5 1.5 0 1 1-3 0 1.5 1.5 0 0 1 3 0z"/>
    </svg>;

export const standardActions = (handlerMap) => {
    return {
        header: "",
        alignment: "right",
        width: "fit-content",
        content: (item, index) => {
            const canEdit = handlerMap?.edit;
            const canDelete = handlerMap?.delete && (!handlerMap?.canDelete || handlerMap?.canDelete(item));
            const canCopy = handlerMap?.copy;
            const canEmbed = handlerMap?.embed;
            return (
            <>
                {(canEdit || canDelete || canCopy || canEmbed) &&
                <DropdownButton title={VerticalEllipsis} size="sm" className="tableRowAction">
                    {handlerMap?.edit && (!handlerMap?.canEdit || handlerMap?.canEdit(item)) && <Dropdown.Item onClick={() => handlerMap.edit(item, index)}>Edit</Dropdown.Item>}
                    {handlerMap?.delete && (!handlerMap?.canDelete || handlerMap?.canDelete(item)) && <Dropdown.Item onClick={() => handlerMap.delete(item, index)}>Delete</Dropdown.Item>}
                    {handlerMap?.copy && (!handlerMap?.canCopy || handlerMap?.canCopy(item)) && <Dropdown.Item onClick={() => handlerMap.copy(item, index)}>Copy</Dropdown.Item>}
                    {handlerMap?.embed && (!handlerMap?.canEmbed || handlerMap?.canEmbed(item)) && <Dropdown.Item onClick={() => handlerMap?.embed(item, index)}>Embed Code</Dropdown.Item>}
                </DropdownButton>}
            </>
            );
        }
    };
};

export default OTable;