import React from "react";
import { Button } from "react-bootstrap";
import { authService } from '../index.js';
import OTable from "../components/OTable";

export const Tenants = () => {
    const userAccount = authService.getUserAccount();

    return (
        <>
            <h4>Tenants</h4>
            <div className="content-toolbar">
                <Button drop="left" 
                    onClick={() => authService.beginNewTenantSignUp() }
                >New Tenant</Button>
            </div>
            <p />
            <OTable items={userAccount.tenants} columns={[
                { header: "Name", width: "auto", content: tenant => tenant},
                { header: "", width: "fit-content", alignment: "right", content: tenant => tenant != userAccount.activeTenant &&
                    <Button size="sm" onClick={() => authService.switchTenant(tenant)}>Switch</Button>
                }
            ]} />
        </>
    );
};