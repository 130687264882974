class SparseUpdate {
    value = {};
    options = {};

    nestedIn = (parentPath) => {
        const paths = parentPath.split(",");
        if (paths.length === 0) {
            return this;
        }

        let rootNode = {};
        let curNode = rootNode;
        for (let i = 0; i < paths.length - 1; i++) {
            curNode = curNode[paths[i]] = {};
        }

        curNode[paths[paths.length-1]] = this.value;
        let nestedUpdate = new SparseUpdate();
        nestedUpdate.value = rootNode;
        nestedUpdate.options = this.options;

        return nestedUpdate;
    };

    static fromValue = (value) => {
        let sparseUpdate = new SparseUpdate();
        sparseUpdate.value = value;
        return sparseUpdate;
    }

    static fromMapUpsert = (paths, item) => {
        let rootNode = {};
        let curNode = rootNode;
        for (let node of paths) {
            curNode = curNode[node] = {};
        }
        curNode[item.key || item.id] = item.value || item;

        let sparseUpdate = new SparseUpdate();
        sparseUpdate.value = rootNode;
        return sparseUpdate;
    };

    static fromMapDelete = (deletedKey) => {
        let sparseUpdate = new SparseUpdate();
        sparseUpdate.value = {};
        sparseUpdate.options = {keysToDelete: [deletedKey]};
        return sparseUpdate;
    };

    static fromArrayReorder = (paths, existingItems, oldIndex, newIndex) => {
        let rootNode = {};
        let curNode = rootNode;
        for (let i = 0; i < paths.length - 1; i++) {
            curNode = curNode[paths[i]] = {};
        }
        const arrName = paths[paths.length - 1];
        let arr = curNode[arrName] = [...existingItems];
        const movedValue = arr.splice(oldIndex, 1)[0];
        arr.splice(newIndex, 0, movedValue);
        
        let sparseUpdate = new SparseUpdate();
        sparseUpdate.value = rootNode;
        return sparseUpdate;
    };

    static fromArrayUpsert = (paths, existingItems, updatedItem, atIndex) => {
        let rootNode = {};
        let curNode = rootNode;
        for (let i = 0; i < paths.length - 1; i++) {
            curNode = curNode[paths[i]] = {};
        }
        const arrName = paths[paths.length - 1];
        let arr = curNode[arrName] = existingItems || [];
        if (atIndex === 0 || atIndex) {
            arr[atIndex] = updatedItem;
        } else {
            arr.push(updatedItem);
        }

        let sparseUpdate = new SparseUpdate();
        sparseUpdate.value = rootNode;
        return sparseUpdate;
    };

    static fromArrayDelete = (paths, existingItems, atIndex) => {
        let rootNode = {};
        let curNode = rootNode;
        for (let i = 0; i < paths.length - 1; i++) {
            curNode = curNode[paths[i]] = {};
        }
        const arrName = paths[paths.length - 1];
        let arr = curNode[arrName] = existingItems || [];
        arr.splice(atIndex, 1)

        let sparseUpdate = new SparseUpdate();
        sparseUpdate.value = rootNode;
        return sparseUpdate;
    };
}

export default SparseUpdate;