import React, { useEffect, useState } from "react";
import { Link, useNavigate } from "react-router-dom"; 
import { Dropdown, DropdownButton, Form, InputGroup } from "react-bootstrap";
import OTable, {standardActions} from "../components/OTable";
import { Connectors as ConnectorsApi } from "../onsight-plus";
import EditModal from "../components/EditModal";
import ConfirmationModal from "../components/ConfirmationModal";

const Connectors = () => {
    const [selectedItem, setSelectedItem] = useState({});
    const [selectedItemIndex, setSelectedItemIndex] = useState(-1);
    const [showDeleteConfirmation, setShowDeleteConfirmation] = useState(false);
    const [showDialog, setShowDialog] = useState(false);
    const [conName, setConName] = useState("");
    const [conType, setConType] = useState("");
    const [connectors, setConnectors] = useState([]);
    const navigate = useNavigate();

    const showAddDialog = (type) => {
        if (type) {
            setConType(type);
        }
        setShowDialog(!showDialog);
    };

    const showDeleteDialog = (item, index) => {
        setSelectedItem(item);
        setSelectedItemIndex(index);
        setShowDeleteConfirmation(true);
    };

    const loadConnectors = async () => {
        try {
            const cons = await ConnectorsApi.getAll();
            setConnectors(cons);
        } catch (error) {
            console.log(error);
        }
    };

    const handleNameChange = event => {
        setConName(event.target.value);
    };

    const addItem = async () => {
        const request = {
            displayName: conName,
            type: conType
        };

        await submitNew(request);
    };

    const submitNew = async (newItem) => {
        try {
            await ConnectorsApi.createNew(newItem);
            await loadConnectors();
            setShowDialog(false);
        } catch (error) {
            console.log(error);
        }
    };

    const deleteSelectedItem = async (item) => {
        try {
            await ConnectorsApi.delete(item.id);
            await loadConnectors();
            setShowDeleteConfirmation(false);
        } catch (error) {
            console.log(error);
        }
    };

    const NewConnectorPanel = React.lazy(() => import(`../panels/connectors/${conType}ConnectorPanel`));

    useEffect(() => {
        loadConnectors();
    }, []);

    return (
        <>
            <h4>Connectors</h4>

            <div className="content-toolbar">
                <DropdownButton id="dropdown-basic-button" title={<span>New</span>}>
                    <Dropdown.Item eventKey="1" onClick={() => showAddDialog("OnsightPlatform")}>Onsight Platform</Dropdown.Item>
                    <Dropdown.Item eventKey="2" onClick={() => showAddDialog("D365")}>Dynamics 365</Dropdown.Item>
                    <Dropdown.Item eventKey="3" onClick={() => showAddDialog("SAP")}>SAP Field Service</Dropdown.Item>
                    <Dropdown.Item eventKey="4" onClick={() => showAddDialog("Https")}>Generic HTTPS</Dropdown.Item>
                    <Dropdown.Item eventKey="5" onClick={() => showAddDialog("SendGrid")}>Send Grid</Dropdown.Item>
                    <Dropdown.Item eventKey="6" onClick={() => showAddDialog("MicrosoftGraph")}>Microsoft Graph</Dropdown.Item>
                </DropdownButton>
            </div>
            <p />
            <OTable items={connectors} columns={[
                { header: "Name", width: "fit-content", content: con => <Link to={`${con.name}`} state={con}>{con.displayName}</Link>},
                { header: "Type", width: "auto", content: con => con.type},
                standardActions({
                    delete: showDeleteDialog
                })
            ]} />

            <EditModal
                itemName="Connector"
                item={selectedItem}
                itemId={selectedItemIndex}
                show={showDialog}
                isNew={true}
                onAddClick={addItem}
                onCancelClick={() => setShowDialog(false)}>
                <InputGroup className="mb-3">
                    <InputGroup.Text id="basic-addon1">Connector Name</InputGroup.Text>
                    <Form.Control name="name"
                        onChange={handleNameChange}
                        aria-label="Connector Name"
                        aria-describedby="basic-addon1" />
                </InputGroup>
            </EditModal>

            <ConfirmationModal
                show={showDeleteConfirmation}
                title="Delete Connector"
                item={selectedItem}
                itemId={selectedItemIndex}
                onYesClick={deleteSelectedItem}
                onNoClick={() => setShowDeleteConfirmation(false)}>
                Are you sure you want to delete '{selectedItem.displayName}'?
            </ConfirmationModal>
        </>
    );
};

export default Connectors;