import React from "react";
import { Button, Modal } from 'react-bootstrap';

const EmbedModal = (props) => {
    return (
        <Modal show={props.show}>
        <Modal.Header closeButton>{props.itemName}</Modal.Header>
        <Modal.Body>
            {props.children}
        </Modal.Body>
        <Modal.Footer>
            <Button variant="secondary" onClick={props.onCopyClick}>Copy</Button>
            <Button variant="secondary" onClick={props.onCloseClick}>Close</Button>
        </Modal.Footer>
    </Modal>
    );
};

export default EmbedModal;