import { useLocation } from "react-router-dom";
import { authService } from './index.js';
import axios from "axios";

const STANDARD_SCOPES = ["openid", "profile", "https://lsonsightplus.onmicrosoft.com/mtrest/generic_oauth2_access"];

const getToken = async (scopes) => {
    const account = authService.getUserAccount();
    let accessToken = "";

    if (!account.isAuthenticated()) {
        throw Error('No active account! Verify a user has been signed in and setActiveAccount has been called.');
    }

    try {
        // First try using cached or silently-refreshed token
        accessToken = await authService.acquireTokenSilent(scopes);
    } catch (error) {
        if (error?.name === "InteractionRequiredAuthError") {
            // Silent token refresh not allowed; show popup to force user re-auth
            accessToken = await authService.acquireTokenPopup(scopes);
        }
    }

    return accessToken;
};

const makeHeaders = async (scopes, extraHeaders) => {
    const accessToken = await getToken(scopes || STANDARD_SCOPES);
    return {
        "Authorization": `Bearer ${accessToken}`,
        ...extraHeaders
    };
};

const get = async (path, scopes, extraHeaders) => {
    let options = {
        headers: await makeHeaders(scopes, extraHeaders)
    };

    return await axios.get(`${process.env.REACT_APP_ONSIGHT_PLUS_API}/v1${path}`, options);
};

const post = async (path, body, scopes, extraHeaders) => {
    let options = {
        headers: await makeHeaders(scopes, extraHeaders)
    };

    return await axios.post(`${process.env.REACT_APP_ONSIGHT_PLUS_API}/v1${path}`, body, options);
};

const put = async (path, body, scopes, extraHeaders) => {
    let options = {
        headers: await makeHeaders(scopes, extraHeaders)
    };

    return await axios.put(`${process.env.REACT_APP_ONSIGHT_PLUS_API}/v1${path}`, body, options);
};

const del = async (path, scopes, extraHeaders) => {
    let options = {
        headers: await makeHeaders(scopes, extraHeaders)
    };

    return await axios.delete(`${process.env.REACT_APP_ONSIGHT_PLUS_API}/v1${path}`, options);
};

export class Identities {
    /**
     * Gets all users in the current tenant.
     */
    static async getAllUsers() {
        const response = await get("/users");
        return response.data;
    }

    /**
     * Removes the given user from the current tenant.
     * @param {*} userId 
     */
    static async removeUser(userId) {
        const response = await del(`/users/${userId}`);
        return response.data;
    }

    /**
     * Gets all app roles assigned to the given user within the specified tenant.
     * These roles include manually-assigned and any inherited by virtue of group memberships.
     * @param {string} userNameId 
     */
    static async getUserRoleAssignments(userNameId) {
        const response = await get(`/users/${userNameId}/roles`);
        return response.data;
    }

    static async assignRole(userNameId, appId, localRoleName) {
        const response = await post(`/users/${userNameId}/roles`, {
            appId,
            roleName: localRoleName
        });
        return response.data;
    }

    static async revokeRole(userNameId, appId, localRoleName) {
        const response = await del(`/users/${userNameId}/roles/${appId}/${encodeURI(localRoleName)}`);
        return response.data;
    }

    static async inviteUser(userEmail, additionalClaims) {
        const response = await post("/users/invite", {
            inviteEmail: userEmail, 
            additionalClaims
        });
        return response.data;
    }
}

export class WebApps {
    static async getAll() {
        const response = await get("/web-apps");
        return response.data;
    }

    static async get(appNameId) {
        const response = await get(`/web-apps/${appNameId}`);
        return response.data;
    }

    static async createNew(request) {
        const response = await post("/web-apps", request);
        return response.data;
    }

    static async update(updatedApp) {
        const response = await put(`/web-apps/${updatedApp.id}`, updatedApp);
        return response.data;
    }

    static async delete(appId) {
        const response = await del(`/web-apps/${appId}`);
        return response.data;
    }

    ///
    /// Page-related APIs.
    ///
    static async getAllPages(appId) {
        const response = await get(`/web-apps/${appId}/pages`);
        return response.data;
    }

    static async createPage(appId, newPageRequest) {
        const response = await post(`/web-apps/${appId}/pages`, newPageRequest);
        return response.data;
    }

    static async deletePage(appId, pageId) {
        const response = await del(`/web-apps/${appId}/pages/${pageId}`);
        return response.data;
    }



    static async getAllConfigs(appId, pageRoute, configType) {
        let url = `/web-apps/${appId}/${pageRoute}/configs`;
        if (configType) {
            url += `?type=${configType}`;
        }

        const response = await get(url);
        return response.data;
    }

    /**
     * Gets the app config with the given config ID.
     * For use by Admin role in editing an app configuration/layout.
     * @param {*} appId
     * @param {*} defId
     * @returns
     */
    static async getConfig(appId, pageRoute, defId) {
        const response = await get(
            `/web-apps/${appId}/${pageRoute}/configs/${defId}`
        );
        return response.data;
    }

    static async createConfig(appId, pageRoute, newConfigRequest) {
        const response = await post(
            `/web-apps/${appId}/${pageRoute}/configs`,
            newConfigRequest
        );
        return response.data;
    }

    static async copyConfig(appId, pageRoute, newConfigRequest) {
        const response = await post(
            `/web-apps/${appId}/${pageRoute}/configs/${newConfigRequest.configId}`,
            newConfigRequest
        );
        return response.data;
    }

    /**
     * Updates the app config (e.g., layout) with the new value.
     * For use in editing an app configuration/layout.
     * @param {*} appId
     * @param {*} updatedConfig
     * @returns
     */
    static async updateConfig(appId, pageRoute, updatedConfig) {
        const response = await put(
            `/web-apps/${appId}/${pageRoute}/configs/${updatedConfig.configId}`,
            updatedConfig
        );
        return response.data;
    }

    static async deleteConfig(appId, pageRoute, defId) {
        const response = await del(
            `/web-apps/${appId}/${pageRoute}/configs/${defId}`
        );
        return response.data;
    }
}

export class Extensions {
    static async getAll() {
        const response = await get("/extensions");
        return response.data;
    }

    static async get(extNameId) {
        const response = await get(`/extensions/${extNameId}`);
        return response.data;
    }

    static async createNew(request) {
        const response = await post("/extensions", request);
        return response.data;
    }

    static async update(updatedExt) {
        const response = await put(`/extensions/${updatedExt.id}`, updatedExt);
        return response.data;
    }

    static async delete(extId) {
        const response = await del(`/extensions/${extId}`);
        return response.data;
    }

    static async getFunctionCode(extNameId, funcType, funcHash) {
        const response = await get(`/extensions/${extNameId}/${funcType}/${funcHash}/code`, STANDARD_SCOPES, {
            "Accept": "text/javascript"
        });
        return response.data;
    }

    static async updateFunctionCode(extNameId, funcType, funcHash, newCode) {
        const response = await put(`/extensions/${extNameId}/${funcType}/${funcHash}/code`, newCode, STANDARD_SCOPES, {
            "Content-Type": "text/javascript"
        });
        return response.data;
    }
}

export class Connectors {
    static async getAll(category) {
        let url = "/connectors";
        if (category) {
            url += `?cat=${category}`;
        }

        const response = await get(url);
        return response.data;
    }

    static async createNew(request) {
        const response = await post("/connectors", request);
        return response.data;
    }

    static async update(connector) {
        const response = await put(`/connectors/${connector.id}`, connector)
        return response.data;
    }

    static async delete(conId) {
        const response = await del(`/connectors/${conId}`);
        return response.data;
    }
}

export class CVModels {
    static async getAll(options) {
        let url = "/cv-models?";
        if (options?.includeVersions) {
            url += `ver=${Boolean(options.includeVersions)}&`;
        }
        if (options?.category) {
            url += `cat=${options.category}`;
        }

        if (url.endsWith("?") || url.endsWith("&")) {
            url = url.substring(0, url.length - 1);
        }

        const response = await get(url);
        return response.data;
    }

    static async createNew(request) {
        const response = await post("/cv-models", request);
        return response.data;
    }

    static async delete(modelId) {
        const response = await del(`/cv-models/${modelId}`);
        return response.data;
    }
}

export class Assets {
    static async get(relPathName) {
        const response = await get(`/assets/${relPathName}`);
        return response.data;
    }

    static async getAll(relPath) {
        const response = await get(`/assets/${relPath}`);
        return response.data;
    }

    static async delete(relPathName) {
        const response = await del(`/assets/${relPathName}`);
        return response.data;
    }
}

export class TenantManagement {
    static async getCVStatus() {
        const response = await get("/tenant/cv/resources");
        return response.data;
    }

    static async enableCV() {
        const response = await post("/tenant/cv/resources");
        return response.data;
    }
}