import React from "react";
import { Button, Modal, Spinner } from 'react-bootstrap';

const EditModal = (props) => {
    const verb = props.isNew ? "Add" : "Update";
    const clickHandler = props.isNew ? props.onAddClick : props.onUpdateClick;

    return (
        <Modal show={props.show}>
            <Modal.Header closeButton>{verb} {props.itemName}</Modal.Header>
            <Modal.Body>
                {props.children}
            </Modal.Body>
            <Modal.Footer>
                <Spinner animation="border" role="status" className={{invisible: !props.busy}} />
                <Button variant="primary" 
                    onClick={() => clickHandler(props.item, props.itemId)} 
                    disabled={props.canSave != null && !Boolean(props.canSave)}>
                        {verb}
                </Button>
                <Button variant="secondary" onClick={props.onCancelClick}>Cancel</Button>
            </Modal.Footer>
        </Modal>
    );
};

export default EditModal;