import React from "react";
import { Button } from "react-bootstrap";
import { authService } from "../index.js";

export const NavigationBar = (props) => {

    const logout = () => {
        authService.logoutRedirect();
    };

    return (
        <>
            <div className="ml-auto">
                <span className="app-title">{props.userAccount.activeTenant}</span>
            </div>
            <div className="ml-auto userControl">
                <span>{props.userAccount.name}&nbsp;&nbsp;</span>
                <Button variant="warning" className="ml-auto" onClick={logout}>Sign out</Button>                      
            </div>
        </>
    );
};