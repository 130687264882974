import React, { useEffect, useState } from "react";
import { Button, Container, Col, Dropdown, DropdownButton, Form, InputGroup, Row, Spinner } from 'react-bootstrap';
import { CVModels as CVModelsApi, TenantManagement as TenantManagementApi } from "../onsight-plus";
import OTable, { standardActions } from "../components/OTable";
import EditModal from "../components/EditModal";
import ConfirmationModal from "../components/ConfirmationModal";

const CVModels = (props) => {
    const [selectedItem, setSelectedItem] = useState({});
    const [selectedItemIndex, setSelectedItemIndex] = useState(-1);
    const [showDeleteConfirmation, setShowDeleteConfirmation] = useState(false);
    const [showDialog, setShowDialog] = useState(false);
    const [modelName, setModelName] = useState("");
    const [modelType, setModelType] = useState("AzureDetectCV");
    const [models, setModels] = useState([]);
    const [isLoading, setIsLoading] = useState(true);
    const [cvEnabled, setCVEnabled] = useState(false);

    const showAddDialog = (type) => {
        if (type) {
            setModelType(type);
        }
        setShowDialog(!showDialog);
    };

    const showDeleteDialog = (item, index) => {
        setSelectedItem(item);
        setSelectedItemIndex(index);
        setShowDeleteConfirmation(true);
    };

    const loadModels = async () => {
        try {
            const cvStatus = await TenantManagementApi.getCVStatus();
            setCVEnabled(cvStatus.isEnabled);

            if (cvStatus.isEnabled) {
                const mods = await CVModelsApi.getAll();
                setModels(mods);
            }

            setIsLoading(false);
        } catch (error) {
            console.log(error);
        }
    };

    const handleModelNameChange = event => {
        setModelName(event.target.value);
    };

    const addItem = async () => {
        const request = {
            displayName: modelName,
            type: modelType,
            category: modelType.includes("Detect") ? "Detect" : "Classify"
        };

        await submitNew(request);
    };

    const submitNew = async (newItem) => {
        try {
            await CVModelsApi.createNew(newItem);
            await loadModels();
            setShowDialog(false);
        } catch (error) {
            console.log(error);
        }
    };

    const deleteSelectedItem = async (item) => {
        try {
            await CVModelsApi.delete(item.id);
            await loadModels();
            setShowDeleteConfirmation(false);
        } catch (error) {
            console.log(error);
        }
    };

    const handleEnableClick = async () => {
        setIsLoading(true);
        try {
            const status = await TenantManagementApi.enableCV();
            setCVEnabled(status.isEnabled);
            setIsLoading(false);
        } catch (error) {
            console.log(error);
        }
    };

    useEffect(() => {
        loadModels();
    }, []);

    const header = <h4>CV Models</h4>;

    if (isLoading) {
        return (
            <>
                {header}
                <Container fluid className="center">
                    <Row>
                        <Col>
                            <Spinner className="xlarge" />
                        </Col>
                    </Row>
                </Container>
            </>
        );
    }

    if (!cvEnabled) {
        return (
            <>
                {header}
                <Container fluid>
                    <Row>
                        <Col>
                            <section>Your tenant is not currently configured for Computer Vision. Click the button below to enable it.</section>
                        </Col>
                    </Row>
                    <Row>
                        <Col>&nbsp;</Col>
                    </Row>
                    <Row>
                        <Col>
                            <Button onClick={handleEnableClick}>Enable Computer Vision</Button>
                        </Col>
                    </Row>
                </Container>
            </>
        );
    }
    return (
        <>
            {header}

            <div className="content-toolbar">
                <DropdownButton id="dropdown-basic-button" title={<span>New</span>}>
                    <Dropdown.Item eventKey="1" onClick={() => showAddDialog("AzureClassifyCV")}>Classification</Dropdown.Item>
                    <Dropdown.Item eventKey="2" onClick={() => showAddDialog("AzureDetectCV")}>Object Detection</Dropdown.Item>
                </DropdownButton>
            </div>
            <p />
            <OTable items={models} columns={[
                { header: "Name", width: "fit-content", content: model => <a href={`https://www.customvision.ai/projects/${model.id}#/manage`} target="_blank">{model.displayName}</a> },
                { header: "Category", width: "auto", content: model => model.category },
                standardActions({
                    delete: showDeleteDialog
                })
            ]} />

            <EditModal
                itemName="CV Model"
                item={selectedItem}
                itemId={selectedItemIndex}
                show={showDialog}
                isNew={true}
                onAddClick={addItem}
                onCancelClick={() => setShowDialog(false)}>
                <InputGroup className="mb-3">
                    <InputGroup.Text id="basic-addon1">Model Name</InputGroup.Text>
                    <Form.Control name="name"
                        onChange={handleModelNameChange}
                        aria-label="Model Name"
                        aria-describedby="basic-addon1" />
                </InputGroup>
            </EditModal>

            <ConfirmationModal
                show={showDeleteConfirmation}
                title="Delete Model"
                item={selectedItem}
                itemId={selectedItemIndex}
                onYesClick={deleteSelectedItem}
                onNoClick={() => setShowDeleteConfirmation(false)}>
                Are you sure you want to delete '{selectedItem.displayName}'?
            </ConfirmationModal>
        </>
    )
};

export default CVModels;