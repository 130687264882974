import React, { useEffect, useState } from "react";
import { Route, Routes } from "react-router-dom";
import { PageLayout } from "./components/PageLayout";
import { Overview } from "./pages/Overview";
import { Tenants } from "./pages/Tenants";
import { Users } from "./pages/Users";
import { UserConfig } from "./pages/UserConfig";
import { WebApps } from "./pages/WebApps";
import { WebAppConfig } from "./pages/WebAppConfig";
import { RestrictedPage } from "./pages/RestrictedPage";
import { LoadingPage } from "./pages/LoadingPage";
import Connectors from "./pages/Connectors";
import ConnectorConfig from "./pages/ConnectorConfig";
import CVModels from "./pages/CVModels";
import { Extensions } from "./pages/Extensions";
import ExtensionConfig from "./pages/ExtensionConfig";
import { loginRequest } from "./authConfig.js";
import { InteractionType } from "@azure/msal-browser";
import { AuthenticatedTemplate, MsalAuthenticationTemplate, UnauthenticatedTemplate } from "@azure/msal-react";
import "./styles/index.css";
import "./styles/App.css";
import { Redeem } from "./pages/Redeem";

const AuthRequired = (props) => {
    return (
        <MsalAuthenticationTemplate interactionType={InteractionType.Redirect} authenticationRequest={loginRequest}>
            {props.isLoading && <LoadingPage />}
            
            <UnauthenticatedTemplate>
                <RestrictedPage />
            </UnauthenticatedTemplate>
            <AuthenticatedTemplate>
                <PageLayout userAccount={props.user}>
                    {props.children}
                </PageLayout>
            </AuthenticatedTemplate>
        </MsalAuthenticationTemplate>
    );
};

const App = ({ authService }) => {
    const [isAuthorized, setIsAuthorized] = useState(false);
    const [notAuthorized, setNotAuthorized] = useState(false);
    const [currentUser, setCurrentUser] = useState(null);

    const onAuthorize = async (userAccount) => {
        setCurrentUser(userAccount);
        try {
            setIsAuthorized(true);
        } catch (error) {
            setNotAuthorized(true);
        }
    };

    useEffect(() => {
        authService.addAuthorizationHandler(onAuthorize);
    }, []);

    // if (!isAuthorized && !notAuthorized) {
    //     return (
    //         <LoadingPage />
    //     );
    // }

    return (
        <>
        <Routes>
            <Route path="/redeem" exact={true} element={<Redeem />} />

            <Route path="/" element={<AuthRequired user={currentUser} isLoading={!isAuthorized && !notAuthorized}><Overview /></AuthRequired>} />
            <Route path="/tenants" element={<AuthRequired user={currentUser} isLoading={!isAuthorized && !notAuthorized}><Tenants /></AuthRequired>} />
            <Route path="/users" element={<AuthRequired user={currentUser} isLoading={!isAuthorized && !notAuthorized}><Users /></AuthRequired>} />
            <Route exact path="/users/:userId" element={<AuthRequired user={currentUser} isLoading={!isAuthorized && !notAuthorized}><UserConfig /></AuthRequired>} />
            <Route path="/connectors" element={<AuthRequired user={currentUser} isLoading={!isAuthorized && !notAuthorized}><Connectors /></AuthRequired>} />
            <Route exact path="/connectors/new" element={<AuthRequired user={currentUser} isLoading={!isAuthorized && !notAuthorized}><ConnectorConfig /></AuthRequired>} />
            <Route exact path="/connectors/:conNameId" element={<AuthRequired user={currentUser} isLoading={!isAuthorized && !notAuthorized}><ConnectorConfig /></AuthRequired>} />
            <Route exact path="/cv-models" element={<AuthRequired user={currentUser} isLoading={!isAuthorized && !notAuthorized}><CVModels /></AuthRequired>} />
            <Route path="/extensions" element={<AuthRequired user={currentUser} isLoading={!isAuthorized && !notAuthorized}><Extensions /></AuthRequired>} />
            <Route exact path="/extensions/:extNameId" element={<AuthRequired user={currentUser} isLoading={!isAuthorized && !notAuthorized}><ExtensionConfig /></AuthRequired>} />
            <Route path="/web-apps" element={<AuthRequired user={currentUser} isLoading={!isAuthorized && !notAuthorized}><WebApps /></AuthRequired>} />
            <Route exact path="/web-apps/:appId" element={<AuthRequired user={currentUser} isLoading={!isAuthorized && !notAuthorized}><WebAppConfig /></AuthRequired>} />
        </Routes>
        </>
    );
}

export default App;