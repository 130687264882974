import React, { useEffect, useState } from "react";
import { Button, Form, InputGroup } from 'react-bootstrap';
import { WebApps as WebAppsApi } from "../onsight-plus";
import OTable, {standardActions} from "../components/OTable";
import Role from "../models/Role";
import EditModal from "../components/EditModal";
import { deepCopy } from "../utils";
import ConfirmationModal from "../components/ConfirmationModal";

export const RolesPanel = (props) => {
    const [webApp, setWebApp] = useState(props.webApp);
    const [selectedItem, setSelectedItem] = useState(new Role());
    const [selectedItemIndex, setSelectedItemIndex] = useState(-1);
    const [showDialog, setShowDialog] = useState(false);
    const [isNewItem, setIsNewItem] = useState(true);
    const [showDeleteConfirmation, setShowDeleteConfirmation] = useState(false);

    const showAddDialog = () => {
        setSelectedItem(new Role());
        setIsNewItem(true);
        setShowDialog(true);
    };

    const showEditDialog = (item, index) => {
        // Edit a copy of the original in case user wants to cancel
        setSelectedItem(deepCopy(item));
        setSelectedItemIndex(index);
        setIsNewItem(false);
        setShowDialog(true);
    };

    const showDeleteDialog = (item, index) => {
        setSelectedItem(item);
        setSelectedItemIndex(index);
        setShowDeleteConfirmation(true);
    };

    const hideDialog = () => {
        setShowDialog(false);
    };

    const addItem = async (item) => {
        try {
            let updatedApp = { ...webApp, customRoles: [...webApp.customRoles, item] };
            updatedApp = await WebAppsApi.update(updatedApp);
            setWebApp(updatedApp);
        }
        catch (error) {
            console.log(error);
        }

        hideDialog();
    };

    const updateItem = async (item, index) => {
        try {
            webApp.customRoles[index] = item;
            await WebAppsApi.update(webApp);
            setWebApp(webApp);
        } catch (error) {
            console.log(error);
        }

        hideDialog();
    };

    const deleteItem = async (item, index) => {
        try {
            webApp.customRoles.splice(index, 1);
            await WebAppsApi.update(webApp);
            setWebApp(webApp);
            setShowDeleteConfirmation(false);
        }
        catch (error) {
            console.log(error);
        }
    };

    const updateSelectedName = (event) => {
        let updatedRole = { ...selectedItem };
        updatedRole.name = event.target.value;
        setSelectedItem(updatedRole);
    };

    const updateSelectedDescription = (event) => {
        let updatedRole = { ...selectedItem };
        updatedRole.description = event.target.value;
        setSelectedItem(updatedRole);
    };

    const loadApp = async () => {
        const app = await WebAppsApi.get(webApp.id);
        setWebApp(app);
    };

    useEffect(() => {
        loadApp();
    }, [props.webApp]);

    return (
        <>
            <span>Roles</span>
            <div>
                <div className="content-toolbar">
                    <Button onClick={showAddDialog} size="sm">New Role</Button>
                </div>
                <p />
                <OTable items={webApp.customRoles} columns={[
                        { header: "Name", width: "fit-content", content: role => role.name},
                        { header: "Description", width: "auto", content: role => role.description},
                        standardActions({
                            edit: showEditDialog,
                            delete: showDeleteDialog
                        })
                    ]} />
            </div>

            <EditModal 
                itemName="Role"
                item={selectedItem}
                itemId={selectedItemIndex}
                show={showDialog}
                isNew={isNewItem}
                onAddClick={addItem}
                onUpdateClick={updateItem}
                onCancelClick={() => setShowDialog(false)}>
                    <InputGroup className="mb-3">
                        <InputGroup.Text id="basic-addon1">Role Name</InputGroup.Text>
                        <Form.Control name="name" 
                            value={selectedItem.name}
                            onChange={updateSelectedName}
                            aria-label="Role Name"
                            aria-describedby="basic-addon1" />
                    </InputGroup>
                    <InputGroup className="mb-3">
                        <InputGroup.Text id="basic-addon2">Description</InputGroup.Text>
                        <Form.Control name="description"
                            value={selectedItem.description} 
                            onChange={updateSelectedDescription}
                            aria-label="Description"
                            aria-describedby="basic-addon2" />
                    </InputGroup>
            </EditModal>

            <ConfirmationModal 
                show={showDeleteConfirmation}
                title="Delete Role"
                item={selectedItem}
                itemId={selectedItemIndex}
                onYesClick={deleteItem}
                onNoClick={() => setShowDeleteConfirmation(false)}>
                    Are you sure you want to delete '{selectedItem.name}'?
            </ConfirmationModal>
        </>
    );
};