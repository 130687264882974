class ConfigOption {
    type = "";
    displayName = "";

    constructor(type, displayName) {
        this.type = type;
        this.displayName = displayName;
    }
}

export default ConfigOption;