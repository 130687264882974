export const copyToClipboard = (text) => {
    navigator.clipboard.writeText(text);
};

export const deepCopy = (obj) => {
    if (!obj) {
        return obj;
    }

    return JSON.parse(JSON.stringify(obj));
};

export const isNullOrEmpty = (obj) => {
    if (obj == null) {
        return true;
    }

    if (Array.isArray(obj)) {
        return obj.length === 0;
    }

    return Object.entries(obj).length === 0;
};

export const Platform = {
    isApple: () => window.navigator.userAgent.indexOf("Mac") >= 0 ||
        window.navigator.userAgent.indexOf("iOS") >= 0
};

export const formatPath = (path, query) => {
    let formattedPath = !path || path === "index" ? "/" : `/${path}`;
    if (query) {
        formattedPath += `?${query}`;
    }

    return formattedPath;
}

export const displayTrim = (text, maxLen) => {
    if (!text || !maxLen || text.length <= maxLen) {
        return text;
    }

    return `${text.substr(0, maxLen - 3)}...`;
}