import React, { useState } from "react";
import { Link, useLocation } from "react-router-dom";
import { Button, Breadcrumb, Form, InputGroup, Modal } from 'react-bootstrap';
import { WebApps as WebAppsApi } from "../onsight-plus";
import WebAppPagesPanel from "../panels/WebAppPagesPanel";
import { RolesPanel } from "../panels/RolesPanel";
import ConfigOption from "../models/ConfigOption";
import mergician from "mergician";
import { deepCopy } from "../utils";

export const WebAppConfig = () => {
    const location = useLocation();
    const webApp = location.state;
    const [showNewRoleDialog, setShowNewRoleDialog] = useState(false);
    const [currentRole, setCurrentRole] = useState({ name: "", description: "" });

    const [savedState, setSavedState] = useState(webApp);
    const [editedState, setEditedState] = useState(deepCopy(webApp));

    const handleChange = (sparseUpdate) => {
        const updatedState = mergician({
            skipKeys: sparseUpdate.options?.keysToDelete || []
        })(editedState, sparseUpdate.value);

        setEditedState(updatedState);
    };

    const configOptions = (app) => {
        if (app.type == "UIBuilder") {
            return [
                new ConfigOption("UIBuilderLayout", "Layout")
            ];
        }

        return [];
    };

    const toggleNewRoleDialog = () => {
        setShowNewRoleDialog(!showNewRoleDialog);
    }

    const addRole = async () => {
        const updated = { ...savedState, customRoles: [...savedState.customRoles, currentRole] };
        const updatedApp = await WebAppsApi.update(updated);
        setSavedState(updatedApp);
        toggleNewRoleDialog();
        setCurrentRole({ name: "", description: "" });
    };
    
    const handleNewRoleChange = (event) => {
        let updatedRole = { ...currentRole };
        updatedRole[event.target.name] = event.target.value;
        setCurrentRole(updatedRole);
    };

    return (
        <>
            <h4>{editedState.displayName}</h4>
            <Breadcrumb>
                <Breadcrumb.Item linkAs={Link} linkProps={{ to: "/web-apps" }}>Web Apps</Breadcrumb.Item>
                <Breadcrumb.Item active>{editedState.displayName}</Breadcrumb.Item>
            </Breadcrumb>
            <p />

            <RolesPanel webApp={editedState} />

            <WebAppPagesPanel webApp={editedState} options={configOptions(editedState)} onChange={handleChange} />

            <Modal show={showNewRoleDialog}>
                <Modal.Header closeButton>Add New Role</Modal.Header>
                <Modal.Body>
                    <InputGroup className="mb-3">
                        <InputGroup.Text id="basic-addon1">Role Name</InputGroup.Text>
                        <Form.Control name="name" onChange={handleNewRoleChange}
                            aria-label="Role Name"
                            aria-describedby="basic-addon1" />
                    </InputGroup>
                    <InputGroup className="mb-3">
                        <InputGroup.Text id="basic-addon2">Description</InputGroup.Text>
                        <Form.Control name="description" onChange={handleNewRoleChange}
                            aria-label="Description"
                            aria-describedby="basic-addon2" />
                    </InputGroup>
                </Modal.Body>
                <Modal.Footer>
                    <Button variant="primary" onClick={addRole}>Add</Button>
                    <Button variant="secondary" onClick={toggleNewRoleDialog}>Cancel</Button>
                </Modal.Footer>
            </Modal>
        </>
    );
};