import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import { Dropdown, DropdownButton, Form, InputGroup } from 'react-bootstrap';
import { WebApps as WebAppsApi } from "../onsight-plus";
import OTable, {standardActions} from "../components/OTable";
import ConfirmationModal from "../components/ConfirmationModal";
import EditModal from "../components/EditModal";
import { deepCopy } from "../utils";
import { authService } from "../index";

export const WebApps = () => {
    const [showDialog, setShowDialog] = useState(false);
    const [appType, setAppType] = useState("Basic");
    const [apps, setApps] = useState([]);
    const [spinnerVisible, setSpinnerVisible] = useState(false);
    const [selectedItem, setSelectedItem] = useState({displayName: "", name: ""});
    const [selectedItemIndex, setSelectedItemIndex] = useState(-1);
    const [showDeleteConfirmation, setShowDeleteConfirmation] = useState(false);
    const [appCreationError, setAppCreationError] = useState("");
    const [isNewApp, setIsNewApp] = useState(true);
    // 64-character subdomain limit (SSL certificate/subdomain limitation)
    const maxSubdomainLength = 64 - `.${authService.getUserAccount().activeTenant}.onsightplus.librestream.com`.length;

    const showNewDialog = (appType) => {
        if (appType) {
            setAppType(appType);
        }
        setIsNewApp(true);
        setSelectedItem({displayName: "", name: ""});
        setAppCreationError("");
        setShowDialog(true);
    };

    const showEditDialog = (item, index) => {
        setIsNewApp(false);
        setSelectedItem(deepCopy(item));
        setSelectedItemIndex(index);
        setAppCreationError("");
        setShowDialog(true);
    };

    const showDeleteDialog = (item, index) => {
        setSelectedItem(item);
        setSelectedItemIndex(index);
        setShowDeleteConfirmation(true);
    };

    const loadApps = async () => {
        try {
            const webApps = await WebAppsApi.getAll();
            setApps(webApps);
        } catch (error) {
            console.log(error);
        }
    };

    const handleDisplayNameChange = event => {
        let updatedItem = { ...selectedItem };
        updatedItem.displayName = event.target.value;
        setSelectedItem(updatedItem);
    };

    const handleSubdomainChange = event => {
        let updatedItem = { ...selectedItem };
        updatedItem.name = event.target.value;
        setSelectedItem(updatedItem);
    };

    const deleteItem = async (item) => {
        try {
            await WebAppsApi.delete(item.id);
            await loadApps();
            setShowDeleteConfirmation(false);
        } catch (error) {
            console.log(error);
        }
    };

    const createApp = async () => {
        const newAppRequest = {
            displayName: selectedItem.displayName,
            type: appType,
            name: selectedItem.name,
            templateVariables: {
            }
        };

        try {
            setSpinnerVisible(true);
            const response = await WebAppsApi.createNew(newAppRequest);
            setSpinnerVisible(false);
            if (response.statusCode >= 400) {
                setAppCreationError(response.message);
                return;
            }

            await loadApps();
            setShowDialog(false);
        } catch (error) {
            setAppCreationError(error.message);
            console.log(error);
        }
    };

    const updateApp = async () => {
        const updateAppRequest = {
            id: selectedItem.id,
            tenantName: selectedItem.tenantName,
            displayName: selectedItem.displayName,
            name: selectedItem.name
        };

        setSpinnerVisible(true);
        try {
            const response = await WebAppsApi.update(updateAppRequest);
            setSpinnerVisible(false);
            if (response.statusCode >= 400) {
                setAppCreationError(response.message);
                return;
            }
    
            await loadApps();
        } catch (error) {
            setSpinnerVisible(false);
            console.log(error);
        }

        setShowDialog(false);
    };

    useEffect(() => {
        loadApps();
    }, []);

    return (
        <>
            <h4>Web Apps</h4>

            <div className="content-toolbar">
                <DropdownButton id="dropdown-basic-button" title={
                    <span>New</span>
                }>
                    <Dropdown.Item eventKey="1" onClick={() => showNewDialog("Basic")}>Basic</Dropdown.Item>
                    <Dropdown.Item eventKey="2" onClick={() => showNewDialog("UIBuilder")}>UI Builder</Dropdown.Item>
                </DropdownButton>
            </div>
            <p />
            <OTable items={apps} columns={[
                { header: "Display Name", width: "fit-content", content: app => <Link to={`${app.name}`} state={app}>{app.displayName}</Link>},
                { header: "Subdomain", width: "fit-content", content: app => app.name},
                { header: "Endpoint", width: "auto", content: app => <a href={`${app.endpoint}`} target="_blank">{app.endpoint}</a>},
                standardActions({
                    edit: showEditDialog,
                    delete: showDeleteDialog
                })
            ]} />

            <EditModal
                itemName="Web App"
                item={selectedItem}
                show={showDialog}
                isNew={isNewApp}
                busy={spinnerVisible}
                onAddClick={createApp}
                onUpdateClick={updateApp}
                onCancelClick={() => setShowDialog(false)}>
                <InputGroup className="mb-3">
                    <InputGroup.Text id="basic-addon1">Display Name</InputGroup.Text>
                    <Form.Control name="displayName" 
                        required={true}
                        value={selectedItem.displayName}
                        onChange={handleDisplayNameChange}
                        aria-label="Display Name"
                        aria-describedby="basic-addon1" />
                </InputGroup>
                <InputGroup className="mb-3">
                    <InputGroup.Text id="basic-addon2">Subdomain</InputGroup.Text>
                    <Form.Control name="subdomain" 
                        required={true}
                        value={selectedItem.name}
                        maxLength={maxSubdomainLength}
                        onChange={handleSubdomainChange}
                        aria-label="Subdomain"
                        aria-describedby="basic-addon2" />
                </InputGroup>
                <span className="text-danger">{appCreationError}</span>
            </EditModal>

            <ConfirmationModal 
                show={showDeleteConfirmation}
                title="Delete Web App"
                item={selectedItem}
                itemId={selectedItemIndex}
                onYesClick={deleteItem}
                onNoClick={() => setShowDeleteConfirmation(false)}>
                    Are you sure you want to delete '{selectedItem.displayName}'?
            </ConfirmationModal>
        </>
    );
}
