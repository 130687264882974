import React, { useState } from "react";
import { Link, useLocation } from "react-router-dom";
import { Breadcrumb, Button } from "react-bootstrap";
import { Extensions as ExtensionsApi } from "../onsight-plus";
import { deepCopy } from "../utils";
import mergician from "mergician";

const ExtensionConfig = () => {
    const location = useLocation();
    const extModel = location.state;

    const [savedState, setSavedState] = useState(extModel);
    const [editedState, setEditedState] = useState(deepCopy(extModel));
    const [isDirty, setIsDirty] = useState(false);

    const handleManualChange = (callback) => {
        const updatedState = { ...editedState };
        callback(updatedState);
        setEditedState(updatedState);
        setIsDirty(true);
    };

    const handleChange = (sparseUpdate) => {
        const updatedState = mergician({
            skipKeys: sparseUpdate.options?.keysToDelete || []
        })(editedState, sparseUpdate.value);

        setEditedState(updatedState);
        setIsDirty(true);
    };

    const handleSave = async () => {
        try {
            await ExtensionsApi.update(editedState);
            setSavedState(deepCopy(editedState));
            setIsDirty(false);
        } catch (error) {
            console.log(error);
        }
    };

    const handleDiscard = () => {
        // Return the editing state to what it was after the last save
        setEditedState(deepCopy(savedState));
        setIsDirty(false);
    };

    const EditorPanel = React.lazy(() => import(`../panels/extensions/${extModel.type}ExtensionPanel`));

    return (
    <>
        <h3>{editedState.displayName}</h3>
        <p />
        <Breadcrumb>
            <Breadcrumb.Item linkAs={Link} linkProps={{ to: "/extensions" }}>Extensions</Breadcrumb.Item>
            <Breadcrumb.Item active>{editedState.displayName}</Breadcrumb.Item>
        </Breadcrumb>
        <div className="content-toolbar">
            <Button onClick={handleSave} size="sm" disabled={!isDirty}>Save</Button>
            <Button onClick={handleDiscard} size="sm" disabled={!isDirty}>Discard Changes</Button>
        </div>

        <p />
        <React.Suspense fallback="Loading...">
            <EditorPanel value={editedState} onChange={handleChange} onManualChange={handleManualChange} />
        </React.Suspense>
    </>
    );
};

export default ExtensionConfig;