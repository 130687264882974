import { EventType, InteractionType } from "@azure/msal-browser";
import { b2cPolicies, loginRequest } from "./authConfig";

export class UserAccount {
    id = "";                // "sub"
    name = "";              // "name"
    email = "";             // "email"
    roles = [];             // "roles"
    activeTenant = "";      // "appTenantName"
    tenants = [];           // "allTenants"

    isAuthenticated() {
        return Boolean(this.id && this.name);
    }
};

export class MsalAuthService {
    #_msal;
    #_eventHandlers = [];
    #_lastMsalEvent;
    config = {
        signInAuthority: "",
        signInScopes: []
    };

    constructor(msal, config) {
        this.#_msal = msal;
        this.config = config;

        this.#_msal.addEventCallback(event => {
            if ((event.eventType === EventType.LOGIN_SUCCESS ||
                event.eventType === EventType.ACQUIRE_TOKEN_SUCCESS ||
                event.eventType === EventType.SSO_SILENT_SUCCESS) &&
                event.payload.account) {

                // Reset "tenant" extra query param once auth request received;
                // this param is only needed when changing tenants (or when first opening the app).
                loginRequest.extraQueryParameters = {};

                // Login succeeded; attempt authorization
                this.#_msal.setActiveAccount(event.payload.account);
                if (this.#shouldCallAuthorize(event)) {
                    // Save the last-known app tenant for continuation
                    localStorage.setItem("lastAppTenant", event.payload.account.idTokenClaims.appTenantName);
                    // Invoke each handler
                    const userAccount = this.getUserAccount();
                    this.#_eventHandlers.forEach(handler => handler(userAccount));
                }
                this.#_lastMsalEvent = event;
            } else if (event.eventType === EventType.ACQUIRE_TOKEN_FAILURE && event.interactionType === InteractionType.Silent) {
                // Silent token acquisition failed; force user interaction to re-login.
                this.#_msal.loginRedirect({
                    authority: this.config.signInAuthority,
                    scopes: this.config.signInScopes,
                    extraQueryParameters: { tenant: localStorage.getItem("lastAppTenant") }
                });
            } else if (event.eventType === EventType.LOGIN_FAILURE ||
                event.eventType === EventType.SSO_SILENT_FAILURE) {

                // Login failure
                const blankUser = new UserAccount();
                this.#_eventHandlers.forEach(handler => handler(blankUser));
            }
        });
    }

    getUserAccount() {
        const msalAccount = this.#_msal.getActiveAccount();
        let user = new UserAccount();
        if (msalAccount) {
            user.id = msalAccount.idTokenClaims.sub;
            user.name =
                msalAccount.idTokenClaims.name ||
                msalAccount.idTokenClaims.displayName ||
                msalAccount.idTokenClaims.signInName;
            user.email = msalAccount.idTokenClaims.email || msalAccount.idTokenClaims.upn;
            user.activeTenant = msalAccount.idTokenClaims.appTenantName;
            user.tenants = msalAccount.idTokenClaims.allTenants;
            user.roles = msalAccount.idTokenClaims.roles;
        }

        return user;
    }

    /**
     * 
     * @param {[string]} scopes 
     * @returns 
     */
    async acquireTokenSilent(scopes) {
        const tokenRequest = {
            scopes,
            account: this.#_msal.getActiveAccount()
        };
       
        const response = await this.#_msal.acquireTokenSilent(tokenRequest);
        return response.accessToken;
    }

    /**
     * 
     * @param {[string]} scopes 
     * @returns 
     */
    async acquireTokenPopup(scopes) {
        const tokenRequest = {
            scopes,
            account: this.#_msal.getActiveAccount()
        };
       
        const response = await this.#_msal.acquireTokenPopup(tokenRequest);
        return response.accessToken;
    }

    beginNewTenantSignUp() {
        this.#_msal.loginRedirect({ 
            authority: b2cPolicies.authorities.newTenant.authority, 
            scopes: loginRequest.scopes 
        });
    }

    logoutRedirect(logoutRequest) {
        logoutRequest = logoutRequest || { account: this.#_msal.getActiveAccount() };
        return this.#_msal.logoutRedirect(logoutRequest);
    }

    async switchTenant(newTenant) {
        return this.#_msal.loginRedirect({
            authority: this.config.signInAuthority,
            scopes: this.config.signInScopes,
            account: this.#_msal.getActiveAccount(),
            extraQueryParameters: { tenant: newTenant }
        });
    }

    addAuthorizationHandler(handler) {
        this.#_eventHandlers.push(handler);

        // If user has already authentcated, run a pre-emptive authorization immediately
        const userAccount = this.getUserAccount();
        if (userAccount.isAuthenticated()) {
            // User already logged in; apply the given handler immediately
            handler(userAccount);
        }
    }

    /**
     * Determines if authorization event handlers should be invoked based on the given MSAL event.
     * @param {EventType} msalEvent 
     * @returns 
     */
    #shouldCallAuthorize(msalEvent) {
        // Most anything will trigger a reauthorization call, EXCEPT 
        // a silent token acquisition beyond the first event.
        // (ie, avoid calling authorization when user is just clicking around).
        return msalEvent.eventType != EventType.ACQUIRE_TOKEN_SUCCESS || 
            msalEvent.interactionType == InteractionType.Redirect ||
            msalEvent.interactionType == InteractionType.Popup ||
            !this.#_lastMsalEvent;
    }
};