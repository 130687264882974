import { authService } from "..";

export const Overview = () => {
    const user = authService.getUserAccount();

    return (
        <>
            <h4>Overview</h4>

            Primary Region: <b>East US</b>
            <br />
            Secondary Region: <b>-</b>
            <br />
            Git Repo: https://LibrestreamBizDev@dev.azure.com/LibrestreamBizDev/OnsightPlusTenants/_git/{user.activeTenant.toLowerCase()}
        </>
    )
};