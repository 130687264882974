import React from "react";
import { Link } from "react-router-dom";
import { Navbar } from "react-bootstrap";
import { NavigationBar } from "./NavigationBar";
import { SideBar } from './SideBar';

export const PageLayout = (props) => {
    const link1 = <Link to="/organizations" />;
    return (
        <>
            <div>
                <Navbar id="mainNav" bg="primary" variant="dark">
                    <a className="navbar-brand" href="/">Onsight Plus</a>
                    <NavigationBar userAccount={props.userAccount} />
                </Navbar>
            </div>
            {/* <div className="sidebar">
                <SideBar />
            </div>
            <br />
            {props.children}
            <br /> */}

            <SideBar />

            <div className="page-content p-4" id="content">
                {props.children}
            </div>
        </>
    );
};
