import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import { Dropdown, DropdownButton, Form, InputGroup } from 'react-bootstrap';
import { Extensions as ExtensionsApi } from "../onsight-plus";
import OTable, {standardActions} from "../components/OTable";
import EditModal from "../components/EditModal";
import ConfirmationModal from "../components/ConfirmationModal";

export const Extensions = () => {
    const [selectedItem, setSelectedItem] = useState({});
    const [selectedItemIndex, setSelectedItemIndex] = useState(-1);
    const [showDeleteConfirmation, setShowDeleteConfirmation] = useState(false);
    const [showDialog, setShowDialog] = useState(false);
    const [extName, setExtName] = useState("");
    const [extType, setExtType] = useState("LinkRouter");
    const [extensions, setExtensions] = useState([]);
    const [canSave, setCanSave] = useState(true);
    const [extraAddDetails, setExtraAddDetails] = useState({});

    const showAddDialog = (type) => {
        if (type) {
            setExtType(type);
        }
        setShowDialog(!showDialog);
    };

    const showDeleteDialog = (item, index) => {
        setSelectedItem(item);
        setSelectedItemIndex(index);
        setShowDeleteConfirmation(true);
    };

    const loadExtensions = async () => {
        try {
            const exts = await ExtensionsApi.getAll();
            setExtensions(exts);
        } catch (error) {
            console.log(error);
        }
    };

    const handleExtNameChange = event => {
        setExtName(event.target.value);
    };

    const addItem = async () => {
        const request = {
            displayName: extName,
            type: extType
        };

        // Include any additional extension-specific details to the Add request
        for (const key of Object.keys(extraAddDetails)) {
            request[key] = extraAddDetails[key];
        }

        await submitNew(request);
    };

    const submitNew = async (newItem) => {
        try {
            await ExtensionsApi.createNew(newItem);
            await loadExtensions();
            setShowDialog(false);
        } catch (error) {
            console.log(error);
        }
    };

    const deleteSelectedItem = async (item) => {
        try {
            await ExtensionsApi.delete(item.id);
            await loadExtensions();
            setShowDeleteConfirmation(false);
        } catch (error) {
            console.log(error);
        }
    };

    useEffect(() => {
        loadExtensions();
    }, []);

    const AddPanel = React.lazy(() => 
        import(`../panels/extensions/Add${extType}ExtensionPanel`)
            .catch(() => ({ default: () => <div />})));

    return (
        <>
            <h4>Extensions</h4>
            
            <div className="content-toolbar">
                <DropdownButton id="dropdown-basic-button" title={<span>New</span>}>
                    <Dropdown.Item eventKey="1" onClick={() => showAddDialog("LinkRouter")}>Link Router</Dropdown.Item>
                    <Dropdown.Item eventKey="2" onClick={() => showAddDialog("Ocr")}>OCR</Dropdown.Item>
                    <Dropdown.Item eventKey="3" onClick={() => showAddDialog("CV")}>Computer Vision</Dropdown.Item>
                    <Dropdown.Item eventKey="4" onClick={() => showAddDialog("SmartCV")}>Smart Vision</Dropdown.Item>
                    <Dropdown.Item eventKey="5" onClick={() => showAddDialog("CallCenter")}>Call Center</Dropdown.Item>
                    <Dropdown.Item eventKey="7" onClick={() => showAddDialog("Api")}>API</Dropdown.Item>
                    <Dropdown.Item eventKey="8" onClick={() => showAddDialog("WebhookListener")}>Webhook Listener</Dropdown.Item>
                    <Dropdown.Item eventKey="9" onClick={() => showAddDialog("FlowNotification")}>Flow Notification</Dropdown.Item>
                </DropdownButton>
            </div>
            <p />
            <OTable items={extensions} columns={[
                { header: "Name", width: "fit-content", content: ext => <Link to={`${ext.name}`} state={ext}>{ext.displayName}</Link>},
                { header: "Type", width: "auto", content: ext => ext.type},
                standardActions({
                    delete: showDeleteDialog
                })
            ]} />

            <EditModal
                itemName="Extension"
                item={selectedItem}
                itemId={selectedItemIndex}
                show={showDialog}
                isNew={true}
                onAddClick={addItem}
                canSave={canSave}
                onCancelClick={() => setShowDialog(false)}>
                    <InputGroup className="mb-3">
                        <InputGroup.Text id="basic-addon1">Extension Name</InputGroup.Text>
                        <Form.Control name="name" 
                            onChange={handleExtNameChange}
                            aria-label="Extension Name"
                            aria-describedby="basic-addon1" />
                    </InputGroup>
                    <React.Suspense fallback={<div />}>
                        <AddPanel preventAdd={prevent => setCanSave(!prevent)} onChange={details => setExtraAddDetails(details)} />
                    </React.Suspense>

            </EditModal>

            <ConfirmationModal 
                show={showDeleteConfirmation}
                title="Delete Extension"
                item={selectedItem}
                itemId={selectedItemIndex}
                onYesClick={deleteSelectedItem}
                onNoClick={() => setShowDeleteConfirmation(false)}>
                    Are you sure you want to delete '{selectedItem.displayName}'?
            </ConfirmationModal>
        </>
    );
};
