import React, { useState } from "react";
import { Link, useLocation } from "react-router-dom";
import { Breadcrumb, Button, Form, InputGroup } from "react-bootstrap";
import { Connectors as ConnectorsApi } from "../onsight-plus";
import { deepCopy } from "../utils";
import mergician from "mergician";
import SparseUpdate from "../models/SparseUpdate";"../models/SparseUpdate";

const ConnectorConfig = () => {
    const location = useLocation();
    const conModel = location.state;

    const [savedState, setSavedState] = useState(conModel);
    const [editedState, setEditedState] = useState(deepCopy(conModel));
    const [isDirty, setIsDirty] = useState(false);
    const [isNew, setIsNew] = useState(!Boolean(conModel.name));

    const handleChange = (sparseUpdate) => {
        const updatedState = mergician({
            skipKeys: sparseUpdate.options?.keysToDelete || []
        })(editedState, sparseUpdate.value);

        setEditedState(updatedState);
        setIsDirty(true);
    };

    const handleSave = async () => {
        try {
            await ConnectorsApi.update(editedState);
            setSavedState(editedState);
            setIsDirty(false);
            setIsNew(false);
        } catch (error) {
            console.log(error);
        }
    };

    const handleDiscard = () => {
        // Return the editing state to what it was after the last save
        setEditedState(deepCopy(savedState));
        setIsDirty(false);
    };

    const EditorPanel = React.lazy(() => import(`../panels/connectors/${conModel.type}ConnectorPanel`));

    return (
        <>
            <Breadcrumb>
                <Breadcrumb.Item linkAs={Link} linkProps={{ to: "/connectors" }}>Connectors</Breadcrumb.Item>
                <Breadcrumb.Item active>{editedState.displayName || `New ${conModel.type}`}</Breadcrumb.Item>
            </Breadcrumb>
            <p />
            <div className="content-toolbar">
                <Button onClick={handleSave} size="sm" disabled={!isDirty}>Save</Button>
                <Button onClick={handleDiscard} size="sm" disabled={!isDirty}>Discard Changes</Button>
            </div>
            <p />

            {isNew && <InputGroup className="mb-3">
                <InputGroup.Text id="basic-addon0">Connector Name</InputGroup.Text>
                <Form.Control name="connectorName"
                    value={editedState.displayName}
                    onChange={evt => handleChange(SparseUpdate.fromValue({ displayName: evt.target.value }))}
                    aria-label="Connector Name"
                    aria-describedby="basic-addon0" />
            </InputGroup>}
            {!isNew && <h3>{editedState.displayName}</h3>}

            <br />
            <React.Suspense fallback="Loading...">
                <EditorPanel value={editedState} onChange={handleChange} />
            </React.Suspense>
        </>
    );
};

export default ConnectorConfig;