import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import { Button, Modal, Form } from "react-bootstrap";
import { Identities } from "../onsight-plus";
import OTable, {standardActions} from "../components/OTable";
import ConfirmationModal from "../components/ConfirmationModal";
import { authService } from "../index";

export const Users = () => {
    const [members, setMembers] = useState(null);
    const [selectedItem, setSelectedItem] = useState({});
    const [selectedItemIndex, setSelectedItemIndex] = useState(-1);
    const [showDeleteConfirmation, setShowDeleteConfirmation] = useState(false);
    const currentTenant = authService.getUserAccount().activeTenant;

    const getMembers = async () => {
        try {
            const users = await Identities.getAllUsers();
            setMembers(users);
        } catch (error) {
            console.log(error);
        }
    }

    const showDeleteDialog = (item, index) => {
        setSelectedItem(item);
        setSelectedItemIndex(index);
        setShowDeleteConfirmation(true);
    };

    const deleteItem = async (item) => {
        try {
            await Identities.removeUser(item.userId);
            await getMembers();
            setShowDeleteConfirmation(false);
        }
        catch (error) {
            console.log(error);
        }
    };

    useEffect(() => {
        getMembers();
    }, []);

    return (
        <>
            <h4>Users</h4>
            {members ? 
                <div>
                    <InviteMember />
                    <OTable items={members} columns={[
                        { header: "Name", width: "fit-content", content: user => <Link to={`${user.userId}`} state={user}>{user.name}</Link>},
                        { header: "Email", width: "auto", content: user => user.email?.toLowerCase()},
                        standardActions({
                            delete: showDeleteDialog
                        })
                    ]} />
                    <p/>
                </div>
            :
                <p>Loading, please wait...</p>
            }

            <ConfirmationModal 
                show={showDeleteConfirmation}
                title="Delete Role"
                item={selectedItem}
                itemId={selectedItemIndex}
                onYesClick={deleteItem}
                onNoClick={() => setShowDeleteConfirmation(false)}>
                    Are you sure you want to remove '{selectedItem.name}' from the {currentTenant} tenant?
            </ConfirmationModal>
        </>
    )
};

const InviteMember = () => {
    const [show, setShow] = useState(false);

    const handleClose = () => setShow(false);
    const handleShow = () => setShow(true);
    
    const [email, setEmail] = useState("abc@xyz.com");
    const [invitation, setInvitation] = useState(null);
    const [statusMsg, setStatusMsg] = useState("");
    const [isOwner, setIsOwner] = useState(false);

    const handleSendInvite = async () => 
    {
        setStatusMsg("generating");
        setInvitation("");

        try {
            const response = await Identities.inviteUser(email, { isAdmin: isOwner.toString() });
            setInvitation(response);
        } catch (error) {
            console.log(error);
        };
    };

    return (
      <>
        <Button variant="primary" style={{display: "flex"}} onClick={handleShow}>
          Invite User
        </Button>
        <p/>
        <Modal show={show} onHide={handleClose}>
          <Modal.Header closeButton>
            <Modal.Title>Invite User</Modal.Title>
          </Modal.Header>
          <Modal.Body>
          <div>
                <div><p><i>Enter email address</i></p>               
                    <div><input type="text" value={email} style={{width: "100%"}} onChange={(e) => { setEmail(e.target.value); setInvitation(""); setStatusMsg(""); }}/></div>
                </div>                     
                <br />
                <Form>
                    <Form.Check
                        id="isTenantAdmin"
                        type="checkbox"
                        variant="primary"
                        checked={isOwner}
                        label="Is co-owner?"
                        value="0"
                        onChange={(e) => { setIsOwner(e.currentTarget.checked); setInvitation(""); }} />
                </Form>
                <br />              
                <div></div>
                {invitation?
                    <>
                        <p />
                        <span>Invitation sent to {email}.</span>
                        <br />
                        <a href={invitation}>Invite Link</a>
                    </>
                :statusMsg?
                    <p>Generating invitation link, please wait...</p>                    
                :
                    <p/>
                }       
            </div>
          </Modal.Body>
          <Modal.Footer>
            <Button variant="secondary" onClick={handleClose}>
              Close
            </Button>
            <Button onClick={handleSendInvite}>Send Invite</Button>
          </Modal.Footer>
        </Modal>
      </>
    );
};
