import React from "react";
import { Button } from "react-bootstrap";
import { Platform } from "../utils";

const OkCancelButtons = (props) => {
    const isApplePlatform = Platform.isApple();

    return (
        <>
            {isApplePlatform &&
            <>
                <Button onClick={props.onCancelClick}>{props.cancelText}</Button>
                <Button onClick={props.onOkClick}>{props.okText}</Button>
            </>
            }
            {!isApplePlatform &&
            <>
                <Button onClick={props.onOkClick}>{props.okText}</Button>
                <Button onClick={props.onCancelClick}>{props.cancelText}</Button>
            </>
            }
        </>
    );
};

export default OkCancelButtons;