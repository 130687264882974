import React from "react";
import { Link } from "react-router-dom";

export const SideBar = () => {
  return (
    <>
      <div className="vertical-nav bg-white" id="sidebar">
        <ul className="nav flex-column bg-white mb-0">
          <li className="nav-item">
            <Link to="/" className="nav-link text-dark font-italic">Overview</Link>
          </li>
          <li className="nav-item">
            <Link to="/tenants" className="nav-link text-dark font-italic">Tenants</Link>
          </li>
          <li className="nav-item">
            <Link to="/users" className="nav-link text-dark font-italic">Users</Link>
          </li>
          <li className="nav-item">
            <Link to="/connectors" className="nav-link text-dark font-italic">Connectors</Link>
          </li>
          <li className="nav-item">
            <Link to="/cv-models" className="nav-link text-dark font-italic">CV Models</Link>
          </li>
          <li className="nav-item">
            <Link to="/extensions" className="nav-link text-dark font-italic">Extensions</Link>
          </li>
          <li className="nav-item">
            <Link to="/web-apps" className="nav-link text-dark font-italic">Web Apps</Link>
          </li>
        </ul>
      </div>
    </>
  )
};
