import React, { useEffect, useState } from "react";
import { Button, Form, InputGroup, Card } from 'react-bootstrap';
import { WebApps as WebAppsApi } from "../onsight-plus";
import OTable, {standardActions} from "../components/OTable";
import WebAppConfigModel from "../models/WebAppConfigModel";
import EditModal from "../components/EditModal";
import { deepCopy, formatPath, displayTrim } from "../utils";
import ConfirmationModal from "../components/ConfirmationModal";
import EmbedModal from "../components/EmbelModal";
import { copyToClipboard } from "../utils";

const WebAppPagesPanel = ({webApp, options, ...props}) => {
    const availableRoles = [...webApp.customRoles, { name: "*", description: "*Any role*" }];
    const [pages, setPages] = useState([]);
    const [configs, setConfigs] = useState([]);
    const [roleOptions, setRoleOptions] = useState(availableRoles);
    const [selectedPage, setSelectedPage] = useState({});
    const [showPageDialog, setShowPageDialog] = useState(false);
    const [isNewPage, setIsNewPage] = useState(true);
    const [showDeletePageConfirmation, setShowDeletePageConfirmation] = useState(false);

    const [selectedConfig, setSelectedConfig] = useState(new WebAppConfigModel("UIBuilderLayout"));
    const [showConfigDialog, setShowConfigDialog] = useState(false);
    const [isNewConfig, setIsNewConfig] = useState(true);
    const [showDeleteConfigConfirmation, setShowDeleteConfigConfirmation] = useState(false);
    const [selectedConfigType, setSelectedConfigType] = useState("");

    const [showEmbedDialog, setShowEmbedDialog] = useState(false);
    const [embedUrl, setEmbedUrl] = useState("");


    const showAddPageDialog = () => {
        setSelectedPage({name: ""});
        setIsNewPage(true);
        setShowPageDialog(true);
    };

    // const showAddConfigDialog = (configOption) => {
    //     setSelectedConfig(new WebAppConfigModel(configOption.type));
    //     setSelectedConfigType(configOption.displayName);
    //     setIsNewConfig(true);
    //     setShowConfigDialog(true);
    // };

    // const showEditConfigDialog = (item) => {
    //     // Edit a copy of the original in case user wants to cancel
    //     setSelectedConfig(deepCopy(item));
    //     setIsNewConfig(false);
    //     setShowConfigDialog(true);
    // };

    // const showCopyConfigDialog = async (item) => {
    //     try {
    //         // Get the latest, full definition of the given config
    //         // const latest = await WebAppsApi.getConfig(webApp.id, item.configId);
    //         // latest.configId = "";
    //         // latest.displayName = `Copy of ${item.displayName}`;
    //         const itemCopy = deepCopy(item);
    //         itemCopy.displayName = `Copy of ${item.displayName}`;
    //         itemCopy.type = item.configType;

    //         setSelectedConfig(itemCopy);
    //         setIsNewConfig(true);
    //         setShowConfigDialog(true);
    //     } catch (error) {
    //         console.log(error);
    //     }
    // };

    // const showDeleteConfigDialog = (item) => {
    //     setSelectedConfig(item);
    //     setShowDeleteConfigConfirmation(true);
    // };

    // const hideConfigDialog = () => {
    //     setShowConfigDialog(false);
    // };

    const hidePageDialog = () => {
        setShowPageDialog(false);
    };

    const showEditPageDialog = (item) => {
        // Edit a copy of the original in case user wants to cancel
        setSelectedPage(deepCopy(item));
        setIsNewPage(false);
        setShowPageDialog(true);
    };

    const showCopyPageDialog = async (item) => {
        try {
            const itemCopy = deepCopy(item);
            itemCopy.name = `${item.name}-copy`;
            itemCopy.copySourceId = item.id;
            itemCopy.id = "";

            setSelectedPage(itemCopy);
            setIsNewPage(true);
            setShowPageDialog(true);
        } catch (error) {
            console.log(error);
        }
    };

    const showDeletePageDialog = (item) => {
        setSelectedPage(item);
        setShowDeletePageConfirmation(true);
    };

    const addPage = async () => {
        try {
            // If the selected page has a non-blank copySourcePath, it implies we're copying a page
            const newPage = await WebAppsApi.createPage(webApp.id, selectedPage);
            const newPages = [...pages, newPage];
            setPages(newPages);
        }
        catch (error) {
            console.log(error);
        }

        hidePageDialog();
    };

    const updatePage = async () => {
        try {
            //const updatedItem = await WebAppsApi.updateConfig(webApp.id, selectedItem);
            const itemIndex = pages.findIndex(item => item.id === selectedPage.id);

            // Replace old version of config item with the updated one
            if (itemIndex >= 0) {
                const newPages = [...pages];
                newPages[itemIndex] = selectedPage;
                const updatedApp = {
                    ...webApp,
                    pages: newPages
                };
                await WebAppsApi.update(updatedApp);
                setPages(newPages);
            }
        } catch (error) {
            console.log(error);
        }

        hidePageDialog();
    };

    const deletePage = async () => {
        try {
            await WebAppsApi.deletePage(webApp.id, selectedPage.id);
            const newPages = pages.filter(page => page.id != selectedPage.id);
            setPages(newPages);
            setShowDeletePageConfirmation(false);
        }
        catch (error) {
            console.log(error);
        }
    };

    const updateSelectedPageName = event => {
        let updatedPage = { ...selectedPage };
        updatedPage.name = event.target.value;
        setSelectedPage(updatedPage);
    };

    // const addConfig = async () => {
    //     try {
    //         // If the selected item already has a configId, it implies we're copying it
    //         const newLayout = selectedConfig.configId 
    //             ? await WebAppsApi.copyConfig(webApp.id, selectedConfig)
    //             : await WebAppsApi.createConfig(webApp.id, selectedConfig);
    //         const newLayouts = [...configs, newLayout];
    //         setConfigs(newLayouts);
    //     }
    //     catch (error) {
    //         console.log(error);
    //     }

    //     hideConfigDialog();
    // };

    // const updateConfig = async () => {
    //     try {
    //         //const updatedItem = await WebAppsApi.updateConfig(webApp.id, selectedItem);
    //         const itemIndex = configs.findIndex(item => item.configId === selectedConfig.configId);

    //         // Replace old version of config item with the updated one
    //         if (itemIndex >= 0) {
    //             const newConfigs = [...configs];
    //             newConfigs[itemIndex] = selectedConfig;
    //             const updatedApp = {
    //                 ...webApp,
    //                 configurations: newConfigs
    //             };
    //             await WebAppsApi.update(updatedApp);
    //             setConfigs(newConfigs);
    //         }
    //     } catch (error) {
    //         console.log(error);
    //     }

    //     hideConfigDialog();
    // };

    // const deleteConfig = async () => {
    //     try {
    //         await WebAppsApi.deleteConfig(webApp.id, selectedConfig.configId);
    //         const newLayouts = configs.filter(layout => layout.configId != selectedConfig.configId);
    //         setConfigs(newLayouts);
    //         setShowDeleteConfigConfirmation(false);
    //     }
    //     catch (error) {
    //         console.log(error);
    //     }
    // };

    // const updateSelectedConfigName = event => {
    //     let updatedLayout = { ...selectedConfig };
    //     updatedLayout.displayName = event.target.value;
    //     setSelectedConfig(updatedLayout);
    // };

    const updateSelectedConfigRole = event => {
        let updatedLayout = { ...selectedConfig };
        updatedLayout.userMapping.role = event.target.value;
        setSelectedConfig(updatedLayout);
    };

    const handleConfigRowReorder = async event => {
        if (!event.destination || event.destination.index === event.source.index) {
            return;
        }

        try {
            const updatedConfigs = [
                ...configs
            ];
            const movedItem = updatedConfigs.splice(event.source.index, 1)[0];
            updatedConfigs.splice(event.destination.index, 0, movedItem);

            const updatedApp = {
                ...webApp,
                configurations: updatedConfigs
            };
            await WebAppsApi.update(updatedApp);
            setConfigs(updatedConfigs);
        } catch (error) {
            console.log(error);
        }
    };

    const loadPages = async () => {
        const pages = await WebAppsApi.getAllPages(webApp.id);
        setPages(pages);
    };

    // const loadConfigs = async (pageRoute) => {
    //     const allConfigs = await WebAppsApi.getAllConfigs(webApp.id, pageRoute);

    //     const visibleConfigs = allConfigs.filter(cfg => options.some(opt => opt.type === cfg.configType));
    //     setConfigs(visibleConfigs);
    // };

    useEffect(() => {
        loadPages();
        const availableRoles = [...webApp.customRoles, { name: "*", description: "*Any role*" }];
        setRoleOptions(availableRoles);
    }, []);

    const showEmbedPageDialog = async (item) => {
        try {
            const embedUrl = `${webApp.endpoint}${formatPath(item.path, item.query)}`
            setEmbedUrl(embedUrl) 
            setShowEmbedDialog(true);
        } catch (error) {
            console.log(error);
        }
    };

    const copyLink = async (embedUrl) => {
        copyToClipboard(embedUrl)
    }

    return (
        <>
            {options?.length > 0 &&
            <>
                <p />
                <br />
                <span>Pages</span>
                <div>
                    <div className="content-toolbar">
                        <Button onClick={showAddPageDialog} size="sm">New Page</Button>
                    </div>
                    <p />
                    <OTable items={pages} getItemId={page => page.id} columns={[
                        { header: "Name", width: "fit-content", content: page => page.name },
                        { header: "Path", width: "fit-content", content: page => <a href={`${webApp.endpoint}${formatPath(page.path, page.query)}`} target="_blank">{displayTrim(formatPath(page.path, page.query), 40)}</a> },
                        { header: "Layout", width: "auto", content: page => page.configurations.length > 0 ? <a href={`${webApp.endpoint}/admin?pageId=${page.id}&configId=${page.configurations[0].configId}`} target="_blank">{page.configurations[0].displayName}</a> : ""},
                        { header: "Role", width: "auto", content: page => page.configurations.length > 0 ? page.configurations[0].userMapping.role : ""},
                        standardActions({
                            edit: showEditPageDialog,
                            delete: showDeletePageDialog,
                            copy: showCopyPageDialog,
                            embed: showEmbedPageDialog
                        })
                    ]} />
                </div>

                <EditModal
                    itemName="Page"
                    item={selectedPage}
                    show={showPageDialog}
                    isNew={isNewPage}
                    onAddClick={addPage}
                    onUpdateClick={updatePage}
                    onCancelClick={() => setShowPageDialog(false)}>
                    <InputGroup className="mb-3">
                        <InputGroup.Text id="basic-addon1">Page Name</InputGroup.Text>
                        <Form.Control name="name"
                            value={selectedPage.name}
                            onChange={updateSelectedPageName}
                            aria-label="Page Name"
                            aria-describedby="basic-addon1" />
                    </InputGroup>
                </EditModal>

                <ConfirmationModal show={showDeletePageConfirmation}
                    title="Delete Page"
                    item={selectedPage}
                    onYesClick={deletePage}
                    onNoClick={() => setShowDeletePageConfirmation(false)}>
                    Are you sure you want to delete page '{selectedPage.name}'?
                </ConfirmationModal>

                <EmbedModal
                    itemName="Embed Code"
                    item={embedUrl}
                    show={showEmbedDialog}
                    onCopyClick={() => copyLink(`<iframe src="${embedUrl}" width="100%" height="1000" title=""></iframe>`)}
                    onCloseClick={() => setShowEmbedDialog(false)}>
                    <InputGroup>
                        <Card>
                            <Card.Body>
                                <span style={{ fontStyle: "italic", cursor: "pointer" }}
                                onClick={() => copyToClipboard(`<iframe src="${embedUrl}" width="100%" height="1000" title=""></iframe>`)}>
                                    {`<iframe src="${embedUrl}" width="100%" height="1000" title=""></iframe>`}
                                </span>
                            </Card.Body>
                        </Card>
                    </InputGroup>
                </EmbedModal>

                {/* TODO: add new configs to each page */}
                {/* <DropdownButton id="dropdown-basic-button" size="sm" title={<span>New</span>}>
                    {options.map((opt, index) => <Dropdown.Item key={opt.type} eventKey={index} onClick={() => showAddDialog(opt)}>{opt.displayName}</Dropdown.Item>)}
                </DropdownButton>                         */}

                {/* TODO: re-add configurations for each page */}
                {/* <OTable items={configs} getItemId={config => config.configId} onRowMoved={handleConfigRowReorder} columns={[
                    { header: "Name", width: "fit-content", content: config => <a href={`${webApp.endpoint}/admin?configId=${config.configId}`} target="_blank">{config.displayName}</a> },
                    { header: "Type", width: "auto", content: config => config.configType },
                    { header: "Role", width: "auto", content: config => config.userMapping.role },
                    standardActions({
                        edit: showEditConfigDialog,
                        delete: showDeleteConfigDialog,
                        copy: showCopyConfigDialog
                    })
                ]} /> */}

                {/* <EditModal 
                    itemName={selectedConfigType}
                    item={selectedConfig}
                    show={showConfigDialog}
                    isNew={isNewConfig}
                    onAddClick={addConfig}
                    onUpdateClick={updateConfig}
                    onCancelClick={() => setShowConfigDialog(false)}>
                        <InputGroup className="mb-3">
                            <InputGroup.Text id="basic-addon1">{`${selectedConfigType} Name`}</InputGroup.Text>
                            <Form.Control name="displayName" 
                                value={selectedConfig.displayName}
                                onChange={updateSelectedConfigName}
                                aria-label={`${selectedConfigType} Name`}
                                aria-describedby="basic-addon1" />
                        </InputGroup>
                        <InputGroup className="mb-3">
                            <InputGroup.Text id="basic-addon2">Role</InputGroup.Text>
                            <Form.Select name="userMapping.role"
                                value={selectedConfig.userMapping.role}
                                onChange={updateSelectedConfigRole}
                                aria-label="Role"
                                aria-describedby="basic-addon2">
                                {roleOptions.map(opt => <option key={opt.name} value={opt.name}>{opt.name}</option>)}
                            </Form.Select>
                        </InputGroup>
                </EditModal>

                <ConfirmationModal show={showDeleteConfigConfirmation}
                    title="Delete Layout"
                    item={selectedConfig}
                    onYesClick={deleteConfig}
                    onNoClick={() => setShowDeleteConfigConfirmation(false)}>
                        Are you sure you want to delete layout '{selectedConfig.displayName}'?
                </ConfirmationModal> */}
            </>
            }
        </>
    );
}

export default WebAppPagesPanel;